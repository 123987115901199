import React from "react";
import Meta from "./../components/Meta";

function FailurePage(props) {
  return (
    <>
      <Meta
        title="Failure"
        description="Create NSFW AI Images in High Quality with just a prompt. No Limits."
      />
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <h1>Payment Failed</h1>
        <p>Oops! It seems there was an issue processing your payment.</p>
        <p>Please check your payment details and try again.</p>
        <button
          style={{
            cursor: "pointer",
            padding: "20px",
            borderRadius: "20px",
            border: "1px solid black",
          }}
          onClick={() => {
            window.location.href = "/pricing";
          }}
        >
          Go to Pricing Section
        </button>
      </div>
    </>
  );
}

export default FailurePage;
