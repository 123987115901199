import React, { useEffect, useState }from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import { Alert } from "@material-ui/lab";
import CardContent from "@material-ui/core/CardContent";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CheckIcon from "@material-ui/icons/Check";
import StarsIcon from '@material-ui/icons/Stars';
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link, useRouter } from "./../util/router";
import { useAuth } from "./../util/auth";
import PaypalCheckoutButton from "./PaypalCheckoutButton";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  activeCard: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    borderColor: theme.palette.primary.main,
    borderWidth: '1px', // Setting border width
    borderStyle: 'solid', // Setting border style /* Highlight with a border */
    backgroundColor: "#303f9f", /* Slightly different background */
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", /* Adding a shadow for depth */
    transform: "scale(1.05)", /* Slightly scale up the card */
    transition: "transform 0.3s ease, box-shadow 0.3s ease" /* Smooth transition for scale and shadow */
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    height: "100%",
    padding: theme.spacing(3),
  },
  price: {
    display: "flex",
    alignItems: "baseline",
  },
  listItem: {
    paddingTop: 2,
    paddingBottom: 2,
  },
  perkIcon: {
    minWidth: 34,
    color: theme.palette.success.main,
  },
  creditIcon: {
    PaddingTop: 90,
    fontSize: 30
  }
}));

function PricingCreditSection(props) {
  const classes = useStyles();
  const router = useRouter();

  const auth = useAuth();
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedPlan, setSelectedPlan] = useState("");
  const [pending, setPending] = useState(false);

  useEffect(() => {
    if (auth.user && !(auth.user.planIsActive)){
      router.push('/')
    }
  }, [auth])

  const plans = [
  
    {
      id: "product4",
      name: "160",
      price: "13.99",
    },
    {
      id: "product5",
      name: "329",
      price: "24.99"
    },
    {
      id: "product6",
      name: "550",
      price: "39.99"
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Box style={{display: "flex", justifyContent: 'center', marginBottom: 50}}>
          <ButtonGroup size="large" variant="outlined" aria-label="large outlined primary button group">
            <Button color="" onClick={() => router.replace("/pricing")}>Plans</Button>
            <Button variant="contained" color="primary">Credit</Button>
          </ButtonGroup>
        </Box>
        
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />

        {message && (
                  <Box mx="auto" mb={4} maxWidth={400}>
                    <Alert severity="success">
                      {message}
                      <span
                        role="img"
                        aria-label="party"
                        style={{ marginLeft: "10px" }}
                      >
                      </span>
                    </Alert>
                  </Box>
                )}

        {errorMessage && (
                  <Box mx="auto" mb={4} maxWidth={400}>
                    <Alert severity="error">
                      {errorMessage}
                      <span
                        role="img"
                        aria-label="party"
                        style={{ marginLeft: "10px" }}
                      >
                      </span>
                    </Alert>
                  </Box>
                )}
        
        <Grid container={true} justifyContent="center" spacing={4}>
          {plans.map((plan, index) => (
            <Grid item={true} xs={12} sm={6} md={4} key={index}>
              <Card className={plan.id == selectedPlan? classes.activeCard : classes.card}>
                <CardContent className={classes.cardContent}>
                  <Box style={{margin: "auto"}}>
                    <Typography variant="h6" component="h2">
                      ${plan.price}
                    </Typography>
                  </Box>
                  <Box className={classes.price} mt={1}>
                    <StarsIcon className={classes.creditIcon}></StarsIcon>
                    <Typography variant="h4">{plan.name} </Typography>
                    <Typography variant="h5"color="textSecondary">
                    &nbsp;Credits
                    </Typography>
                  </Box>

                  { plan.id == selectedPlan
                    ?
                    <Box mt="auto" pt={3}>
                      <Button
                        component={Link}
                        to={
                          auth.user
                            ? `/purchase/${plan.id}`
                            : `/auth/signup?next=/purchase/${plan.id}`
                        }
                        variant="contained"
                        color="primary"
                        size="medium"
                        fullWidth={true}
                        style={{ marginBottom: '8px' }}
                      >
                        Pay with Card
                      </Button>
                      <PaypalCheckoutButton
                        productId={plan.id}
                        setMessage={setMessage}
                        setErrorMessage={setErrorMessage}
                      />
                    </Box>
                    :
                    <Box mt="auto" pt={3}>
                      <Button
                        onClick={() => setSelectedPlan(plan.id)}
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth={true}
                      >
                        Choose
                      </Button>
                    
                    </Box>
                    }
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
}

export default PricingCreditSection;
