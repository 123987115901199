import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Generator from "./Generator";
import FeaturesSection from "./FeaturesSection";
import { Link, useRouter } from "./../util/router";
import { useAuth } from "./../util/auth";

function GeneratorSection(props) {
  const auth = useAuth();
  const router = useRouter();

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="sm">
      {router.query.paid && auth.user?.planIsActive && (
          <Box mx="auto" mb={4} maxWidth={400}>
            <Alert severity="success">
              You are now subscribed to the {auth.user?.planId} plan
              <span
                role="img"
                aria-label="party"
                style={{ marginLeft: "10px" }}
              >
                🥳
              </span>
            </Alert>
          </Box>
        )}
        {router.query.paid && router.query.credit && (
          <Box mx="auto" mb={4} maxWidth={400}>
            <Alert severity="success">
              You successfully bought {router.query.credit} credit!
              <span
                role="img"
                aria-label="party"
                style={{ marginLeft: "10px" }}
              >
                🥳
              </span>
            </Alert>
          </Box>
        )}    
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={1}
          textAlign="center"
        />
         
        <Generator
          buttonAction={props.buttonAction}
          providers={props.providers}
        />
        <FeaturesSection
            bgColor="default"
            size="medium"
            bgImage=""
            bgImageOpacity={1}
          />
      </Container>
    </Section>
  );
}

export default GeneratorSection;
