import React from "react";
import { useDarkMode } from "../../util/theme";
import "../../styles/ainude.css";
import hero1 from "../../imgs/hero 1.png";
import hero2 from "../../imgs/hero 2.jpg";
import hero3 from "../../imgs/hero 3.png";
import hero4 from "../../imgs/hero 4.jpg";
import hero5 from "../../imgs/hero 5.jpg";
import Meta from "../Meta";

const AiNudeGenerator = () => {
  const { value: darkMode } = useDarkMode();

  const backgroundColor = darkMode ? "#0F0B29" : "#EFEFEF";
  const textColor = darkMode ? "#fff" : "#000";
  // const linkColor = darkMode ? "#99f" : "#000";

  const meta = {
    title: "Best AI Nude Generator to Generate Free Nudes",
    description:
      "Explore the AI Nude Generator with advanced AI. Try this Free Nude Generator and create your favourite character.",
    image: "/path/to/your/image.jpg",
    domain: "https://www.nsfwartgenerator.ai",
  };

  return (
    <div>
      <Meta {...meta} />
      <div id="saasbox_page_content" data-login-status={0}>
        <main className="main-content">
          <header
            className={`header text-white h-fullscreen pb-8 text-center text-lg-left ${
              darkMode ? "dark-mode-header" : ""
            }`}
            style={{
              backgroundImage: "url(/hero.png)",
            }}
          >
            <div
              className="overlay opacity-40"
              style={{ backgroundColor: "#000" }}
            />
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-lg-8 mx-auto align-self-center">
                  <h1 className="display-3 fw-500">Free AI Nude Generator</h1>
                  <p className="lead-2 text-white mt-6 mb-8">
                    With our free AI Nude Generator bring your wild fantasies to
                    life. Say goodbye to searching for free nude images on the
                    internet instead create it yourself using our <a href="https://www.nsfwartgenerator.ai/">NSFW AI Art Generator</a> 
                    with complete ease. Craft stunning AI nude art in Realistic
                    or Anime style that perfectly tailors all your needs.
                  </p>
                  <p className="gap-xy">
                    <a
                      className="btn btn-primary btn-lg btn-round"
                      href="/create"
                      style={{ minWidth: 200 }}
                    >
                      {" "}
                      Get started{" "}
                    </a>
                    <a
                      className="btn btn-warning btn-lg btn-round"
                      href="/create"
                      style={{ minWidth: 200 }}
                    >
                      {" "}
                      How to use it{" "}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </header>

          <section
            className="section pb-0"
            id="feature"
            style={{ backgroundColor }}
          >
            <div className="container">
              <header className="section-header" style={{ color: textColor }}>
                <small style={{ color: "#999" }}>Feature</small>
                <h2 style={{ color: textColor }}>
                  4&nbsp;Features&nbsp;of&nbsp;AI&nbsp;Nude&nbsp;Generator
                </h2>
                <hr style={{ borderColor: textColor }} />
              </header>
              <div className="row gap-y align-items-center my-9">
                <div className="col-md-6">
                  <h4 style={{ color: textColor }}>
                    <strong>
                      Make Unlimited AI Nude Art From Prompt in Seconds{" "}
                    </strong>
                  </h4>
                  <p style={{ color: textColor }}>
                    <br />
                    Stop wasting time searching for free Nude images on the
                    internet. Now, you can create them yourself! Use our AI
                    nudes generator to create impressive and realistic-looking
                    nude images without any technical or complex skills. Simply
                    describe the image you desire in the prompt section, and our
                    AI Nude Generator will instantly generate a stunning nude
                    image for you in a matter of a few seconds.{" "}
                  </p>
                </div>
                <div className="col-md-5 mr-auto order-md-first">
                  <img
                    className="rounded shadow-2"
                    src={hero1}
                    alt="make custom nude with AI"
                  />
                </div>
              </div>
              {/* Second row */}
              <div className="row gap-y align-items-center my-9">
                <div className="col-md-6">
                  <h4 style={{ color: textColor }}>
                    <strong>
                      Create Realistic or Anime Style AI-Generated Nudes
                    </strong>
                  </h4>
                  <p style={{ color: textColor }}>
                    <br />
                    Whether you prefer anime-style art or stunning realistic
                    images, our AI Nude generator can cover all your needs and
                    requirements here. Our AI nude platform has implemented a
                    variety of popular and in-demand AI models to ensure that
                    all aspects of users' requirements are fulfilled
                    effortlessly, whether they be realistic, 2D anime, 2D, etc.
                    <br />
                  </p>
                </div>
                <div className="col-md-5 ml-auto">
                  <img
                    className="rounded shadow-2"
                    src={hero2}
                    alt="Realistic and Anime AI nude art"
                  />
                </div>
              </div>
              {/* Third row */}
              <div className="row gap-y align-items-center my-9">
                <div className="col-md-6">
                  <h4 style={{ color: textColor }}>
                    <strong>Explore Tons of Fake AI Nudes</strong>
                  </h4>
                  <p style={{ color: textColor }}>
                    <br />
                    Enter the captivating world of artificial intelligence with
                    ease by utilizing our AI Nude generator. Browse through our
                    community section and explore a wide range of pre-existing
                    AI Nude artworks from seductive anime-inspired illustrations
                    to eye-catching masterpieces, using our advanced AI
                    technology. Discover endless creative and innovative
                    prospects with our AI Nude Generator.
                    <br />
                  </p>
                </div>
                <div className="col-md-5 mr-auto order-md-first">
                  <img
                    className="rounded shadow-2"
                    src={hero3}
                    alt="ai nude art"
                  />
                </div>
              </div>
              {/* Fourth row */}
              <div className="row gap-y align-items-center my-9">
                <div className="col-md-6">
                  <h4 style={{ color: textColor }}>
                    <strong>Our DeepNude Generator is 100% Private</strong>
                  </h4>
                  <p style={{ color: textColor }}>
                    <br />
                    Your privacy is important to us. Don’t worry about your data
                    getting leaked or shared with any third party. Our AI
                    DeepNude generator guarantees 100% protection and strictly
                    keeps your data confidential without any usage records.
                    <br />
                  </p>
                </div>
                <div className="col-md-5 ml-auto">
                  <img
                    className="rounded shadow-2"
                    src={hero4}
                    alt="safe ai nude generator"
                  />
                </div>
              </div>
            </div>
          </section>

          <section
            className="section"
            id="how-to-use"
            style={{ backgroundColor, color: textColor }}
          >
            <div className="container">
              <header className="section-header">
                <h2 style={{ color: textColor }}>
                  How to Use Nude AI Generator to Make Nude Art?
                </h2>
                <hr style={{ borderColor: textColor }} />
                <p className="lead">
                  Create stunning AI nude art in seconds by simply following
                  these 4 simple steps:{" "}
                </p>
              </header>
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="video-btn-wrapper">
                    <img
                      className="shadow-4 rounded-lg"
                      src={hero5}
                      alt="ai nude"
                    />
                    <a
                      className="btn btn-info btn-xl btn-circle display-none"
                      href="*"
                      data-provide="lightbox"
                    >
                      <i className="fa fa-play " />{" "}
                    </a>
                  </div>
                </div>
                <div className="col-lg-6">
                  <ol className="step">
                    <li className="step-item">
                      <div className="step-icon">
                        <span className="iconbox">1</span>
                      </div>
                      <div className="step-content">
                        <h6 style={{ color: textColor }}>
                          Visit the official website of the Nude AI Generator.
                        </h6>
                      </div>
                    </li>
                    <li className="step-item">
                      <div className="step-icon">
                        <span className="iconbox">2</span>
                      </div>
                      <div className="step-content">
                        <h6 style={{ color: textColor }}>
                          Sign up by providing a valid email address and
                          password.
                        </h6>
                      </div>
                    </li>
                    <li className="step-item">
                      <div className="step-icon">
                        <span className="iconbox">3</span>
                      </div>
                      <div className="step-content">
                        <h6 className="fw-500" style={{ color: textColor }}>
                          Describe your desired nude image in the prompt box and
                          click on “Generate”
                        </h6>
                      </div>
                    </li>
                    <li className="step-item">
                      <div className="step-icon">
                        <span className="iconbox">4</span>
                      </div>
                      <div className="step-content">
                        <h6 className="fw-500" style={{ color: textColor }}>
                          Wait for a few seconds till the AI processes your
                          request. Once your image is ready you can download it
                          for free.
                        </h6>
                      </div>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

export default AiNudeGenerator;
