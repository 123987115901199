import React from "react";
import Meta from "../components/Meta";
import ContactSection from "../components/ContactSection";
import { useRouter } from "../util/router";

function ContactPage(props) {
  document.title = "Contact NSFWartgenerator - Get in Touch with Our Team";

  return (
    <>
      <Meta title="Contact" />
      <ContactSection
        bgColor="default"
        size="normal"
        bgImage=""
        bgImageOpacity={1}
      />
    </>
  );
}

export default ContactPage;
