import React from 'react'
import AiNudeGenerator from '../components/AiNudeGenerator/ainudegenerator'

const AiNudePage = () => {
  return (
    <div>
      <AiNudeGenerator />
    </div>
  )
}

export default AiNudePage
