import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import StyleIcon from '@material-ui/icons/Style';
import BrushIcon from '@material-ui/icons/Brush';
import ImageIcon from '@material-ui/icons/Image';
import Section from "./Section";

const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin
  container: {
    padding: `0 ${theme.spacing(4)}px`,
  },
}));

function FeaturesSection(props) {
  const classes = useStyles();

  const items = [
    {
      title: "STEP 1",
      subtitle:
        "Select from our diverse range: Real, Hyper Real, Anime, or Hyper Anime.",
      icon: StyleIcon,
      iconColor: "blackwhite",
    },
    {
      title: "STEP 2",
      subtitle:
        "Pick a pose, or let your imagination run wild with a custom prompt.",
      icon: BrushIcon,
      iconColor: "blackwhite",
    },
    {
      title: "STEP 3",
      subtitle:
        "Click 'Generate' and witness AI magic. Your personalized NSFW art will be ready in moments.",
      icon: ImageIcon,
      iconColor: "blackwhite",
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className={classes.container}>
        <Grid container={true} justifyContent="center" spacing={7}>
          {items.map((item, index) => (
            <Grid item={true} xs={12} md={4} key={index}>
              <Box textAlign="center">
                <Box color={item.iconColor} fontSize="4.5rem">
                  <item.icon fontSize="inherit" />
                </Box>
                <Typography variant="h5" gutterBottom={true}>
                  {item.title}
                </Typography>
                <Box mt={3}>
                  <Typography variant="subtitle1">{item.subtitle}</Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
}

export default FeaturesSection;
