import React, { useState, useEffect } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Typography, Grid, Divider } from "@material-ui/core";
import { Link } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const AgeVerification = () => {
  const [open, setOpen] = useState(true);
  const [userIsOver18, setUserIsOver18] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (userIsOver18) {
      setOpen(false);
    }
  };

  const handleAgeConfirmation = () => {
    setUserIsOver18(true);
    setOpen(false);
    // Store the age verification status in local storage
    localStorage.setItem('ageVerified', 'true');
  };

  useEffect(() => {
    // Check local storage for the age verification status
    const ageVerified = localStorage.getItem('ageVerified');
    if (ageVerified === 'true') {
      setUserIsOver18(true);
      setOpen(false);
    }
  }, []);

  return (
    <Dialog
      maxWidth={"xs"}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={userIsOver18 ? false : handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <Box style={{ padding: "20px", backgroundColor: "#0f0c13" }}>
        <DialogTitle
          style={{
            color: "#e85275",
            fontWeight: "600",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h5" color="#e85275" align="center">
            {"Warning 18+"}
          </Typography>
        </DialogTitle>
        <Divider />
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "600",
          }}
        >
          <Typography align="center" style={{color:"silver" , fontWeight: "600" }}>
            {"This site is for adults only!"}
          </Typography>
          <Typography align="center" style={{color:"silver" ,  fontWeight: "600" }}>
            {"It contains AI-generated adult content"}
          </Typography>
        </Box>

        <DialogContent     style={{ padding: "8px 2px"}}
        >
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ color: "silver", fontSize: "13px", fontWeight: "500", textAlign: "justify" }}
          >
            By entering this website you confirm that you are 18 years old or more. By using the site, you agree to our Terms of services. Our privacy policy details how we collect and use our data. We use cookies for analytics and spam detection. All content on this website is AI-generated! Any generation that resembles real people is purely coincidental.
          </DialogContentText>
        </DialogContent>

        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={6}>
            <Button
              component={Link} to="/legal/terms-of-service"
              variant="outlined"
              style={{
                color: "#e85275",
                borderRadius: "5px",
                textTransform: "none",
                width: "100%",
                textDecoration: "none",
              }}
              onClick={handleClose}
            >
              <Typography variant="subtitle2" style={{ color: "#e85275" }}>Terms Of Services</Typography>
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              component={Link} to="/legal/privacy-policy"
              variant="outlined"
              style={{
                color: "#e85275",
                borderRadius: "5px",
                textTransform: "none",
                width: "100%",
              }}
              onClick={handleClose}
            >
              <Typography variant="subtitle2" style={{ color: "#e85275" }}>Privacy policy</Typography>
            </Button>
          </Grid>
        </Grid>

        <DialogActions
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
            width:"100%",
            padding:"0px"
          }}
        >
          <Button
            variant="contained"
            style={{
              width: "100%",
              backgroundColor: "#e85275",
              color: "white",
              borderRadius: "5px",
              textTransform: "none",
              '&:hover': {
                backgroundColor: "#ff678f",
              },
              '&:active': {
                backgroundColor: "#c12d54",
              },
            }}
            onClick={handleAgeConfirmation}
          >
            I am over 18 - Continue
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
