import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "&:not(:last-child)": {
      marginBottom: "2rem",
    },
  },
  subtitle: {
    maxWidth: 700,
    display: "inline-block",
  },
  h1: {
    fontSize: "3.0rem", // Adjust the font size for h1 tags as needed
  },
  h2: {
    fontSize: "2.0rem", // Adjust the font size for h2 tags as needed
  },
}));

function SectionHeader(props) {
  const classes = useStyles();

  // Coerce size into the range [1, 6]
  const validSize = Math.min(Math.max(props.size || 1, 1), 6);

  const { subtitle, title, smallTitle, className, ...otherProps } = props;

  // Render nothing if no title or subtitle
  if (!title && !subtitle && !smallTitle) {
    return null;
  }

  return (
    <Box
      component="header"
      className={classes.root + (className ? ` ${className}` : "")}
      {...otherProps}
    >
      {title && (
        <Typography
          className={validSize === 1 ? classes.h1 : validSize === 2 ? classes.h2 : ""}
          variant={`h${validSize}`}
          component={`h${validSize}`}
          gutterBottom={!!subtitle || !!smallTitle}
        >
          {title}
        </Typography>
      )}
      {smallTitle && (
        <Typography
          className={validSize + 1 === 1 ? classes.h1 : validSize + 1 === 2 ? classes.h2 : ""}
          variant={`h${validSize + 1}`}
          component={`h${validSize + 1}`}
          gutterBottom={!!subtitle}
        >
          {smallTitle}
        </Typography>
      )}

      {subtitle && (
        <Typography variant="subtitle1" className={classes.subtitle}>
          {subtitle}
        </Typography>
      )}
    </Box>
  );
}

export default SectionHeader;
