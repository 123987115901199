import React from "react";
import Container from "@material-ui/core/Container";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

const useStyles = makeStyles((theme) => ({
  accordion: {
    // Remove shadow
    boxShadow: "none",
    "&:before": {
      // Remove default divider
      display: "none",
    },
    // Add a custom border
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  expanded: {
    margin: `0 !important`,
  },
  summary: {
    minHeight: 78,
  },
  summaryContent: {
    margin: "0 !important",
  },
}));

function FaqSection(props) {
  const classes = useStyles();

  const items = [
    {
      question: "How Do NSFW AI Image Generators Work?",
      answer: "Our NSFW AI Image Generator utilizes advanced machine learning algorithms trained on vast datasets of NSFW content. When you input a text prompt, the AI interprets your request and generates an image that matches your description. The process combines both the art of design and the precision of technology to bring your fantasies to life.",
    },
    {
      question: "Is Commercial Use Allowed?",
      answer: "Yes, images generated using our platform can be used for commercial purposes. However, we advise users to ensure that the content adheres to the guidelines and regulations of the platform or medium where they intend to use it.",
    },
    {
      question: "Are AI Generated Images Copyrighted?",
      answer: "Images generated by our NSFW Art Generator are free from traditional copyrights since they are created by AI. However, they are subject to our platform's terms of service. Users have the right to use, modify, and distribute these images, but selling the original generated content without modification is prohibited.",
    },
    {
      question: "Is My AI-generated Image Going to Be Completely Unique?",
      answer: "While our AI strives to produce unique images for every prompt, there's a possibility of similarities with other generated content due to the nature of AI training. However, the chances of producing an identical image to another user's request are extremely low.",
    },
    {
      question: "How Do I Ensure My Privacy on the Platform?",
      answer: "Your privacy is our utmost priority. All generated images and prompts are stored securely and are not shared with third parties. Additionally, we offer a private mode for enhanced discretion.",
    },
    {
      question: "Can I Request Custom Styles or Poses Not Listed?",
      answer: "Absolutely! We're always looking to expand our offerings. If you have a specific style or pose in mind, feel free to reach out to our support team, and we'll do our best to accommodate your request.",
    },
    {
      question: "Are There Any Restrictions on the Type of Content I Can Generate?",
      answer: "While our platform is designed for NSFW content generation, we adhere to strict ethical guidelines. Any content that promotes harm, non-consensual acts, or illegal activities is strictly prohibited.",
    },
    {
      question: "How Do I Report Issues or Provide Feedback?",
      answer: "We value user feedback and are always looking to improve. If you encounter any issues or have suggestions, please contact our support team through the 'Contact Us' page.",
    },
    {
      question: "Is There a Limit to How Many Images I Can Generate?",
      answer: "The number of images you can generate depends on your subscription plan. Free users have a limited number of generations per month, while premium users enjoy unlimited access.",
    },
    {
      question: "Do You Offer Bulk Pricing or Enterprise Solutions?",
      answer: "Yes, we offer tailored solutions for businesses and individuals with bulk requirements. Please get in touch with our sales team for more information.",
    },
  ];


  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="md">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />

        {items.map((item, index) => (
          <Accordion
            classes={{
              root: classes.accordion,
              expanded: classes.expanded,
            }}
            key={index}
          >
            <AccordionSummary
              classes={{
                root: classes.summary,
                content: classes.summaryContent,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`faq-panel-${index}`}
            >
              <Typography variant="h6">{item.question}</Typography>
            </AccordionSummary>
            <AccordionDetails id={`faq-panel-${index}`}>
              <Typography>{item.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </Section>
  );
}

export default FaqSection;
