import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Meta from "./../components/Meta";

function ThankyouPage(props) {
  document.title = "Thank you - NSFWartgenerator";

  return (
    <>
      <Helmet>
        <script
          src="https://script.tapfiliate.com/tapfiliate.js"
          type="text/javascript"
          async
        />
        <script type="text/javascript">
          {`
    (function (t, a, p) {
      t.TapfiliateObject = a;
      t[a] = t[a] || function () {
        (t[a].q = t[a].q || []).push(arguments)
      }
    })(window, 'tap');
    tap('create', '48886-c28275', { integration: "stripe" });
    tap('trial', 'cus_QCTMW2n3N9TAaG');
    console.log("captured")
  `}
        </script>
        <script type="text/javascript">
          {`
    function tapGetQueryParams() {
      var r = {},
        t = function (r) {
          return decodeURIComponent(r.replace(/\+/g, ""));
        },
        n = location.search.substring(1).split("&");
      for (var e in n) {
        var a = n[e].toString().split("=");
        a.length > 1 && (r[t(a[0])] = t(a[1]));
      }
      return r;
    }
    var params = tapGetQueryParams();
    tap('create', '48886-c28275', { integration: "paypal" });
    if (params.st == 'Completed') {
      var amt = params.amt || 1;
      var tx = params.tx || null;
      tap('conversion', tx, amt);
    } else {
      tap('detect');
    }
  `}
        </script>
      </Helmet>
      <Meta
        title="Thank You"
        description="Create NSFW AI Images in High Quality with just a prompt. No Limits."
      />
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <h1>Payment Successful!</h1>
        <p>Thank you for your purchase.</p>
        <p>Your order will be processed shortly.</p>
        <button
          style={{
            cursor: "pointer",
            padding: "20px",
            borderRadius: "20px",
            border: "1px solid black",
          }}
          onClick={() => {
            window.location.href = "/";
          }}
        >
          Go to Home Page
        </button>
      </div>
    </>
  );
}
export default ThankyouPage;
