import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Section from "./Section";
import { Link } from "../util/router";
import LongContent from "./LongContent";

function AboutSection(props) {
  const data = {
    domain: "company.com",
    companyName: "Company",
  };

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
      <LongContent>
      <h1 style={{textAlign: 'center'}}>About NSFW Art Generator</h1>
      <h2>Crafting Desires with Precision</h2>
      
<p>Dive deep into the world of NSFWartgenerator. Discover how we bridge the gap between human fantasies and the limitless potential of AI to create impeccable NSFW erotic art tailored to your desires.</p>      
      <h2>Our Mission:</h2>
      <p>To empower users with the tools to bring their deepest desires to life, ensuring a seamless blend of art and technology.</p>
      
      <h2>Our Vision:</h2>
      <p>To be the leading NSFW art generator globally, setting benchmarks in quality, innovation, and user experience.</p>
      
      <h2>Why Choose NSFW Art Generator?</h2>
      <ul>
        <li><strong>Advanced Algorithms:</strong> Our state-of-the-art AI is trained on vast datasets, ensuring high-quality results tailored to your prompts.</li>
        <li><strong>User-Centric Approach:</strong> We prioritize user feedback, continuously evolving to cater to your needs and desires.</li>
        <li><strong>Ethical Standards:</strong> While we delve into the world of NSFW content, we uphold strict ethical guidelines, ensuring all generated content is consensual and adheres to global standards.</li>
      </ul>
      </LongContent>
      </Container>
    </Section>
  );
}

export default AboutSection;
