import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Typography, Divider } from "@material-ui/core";
import { ArrowForwardSharp, Done } from '@material-ui/icons'
import { DoneAll } from '@material-ui/icons';
import { CancelOutlined } from '@material-ui/icons';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PromptWarning = (props) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
    >
      <Box style={{ padding: "20px", backgroundColor: "#0f0c13" }}>
        <DialogTitle
          style={{
            color: "#e85275",
            fontWeight: "600",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h5" align="center">
            {"Prompt Warning"}
          </Typography>
        </DialogTitle>
        <Divider />
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "600",
          }}
        >
          <Typography align="center" style={{ color: "silver", fontWeight: "600" }}>
            {"Your prompt does not comply with our guidelines. Please ensure that your content aligns with our policies. To assist you, we've made some adjustments to your prompt."}
          </Typography>
         
        </Box>

        <DialogContent style={{ padding: "8px 2px" }}>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ color: "silver", fontSize: "13px", fontWeight: "500", textAlign: "justify" }}
          >
         <Box>
  {props.prompt !== props.improvedPrompt && (
    <Typography gutterBottom align="left" variant="subtitle1" style={{ color: "silver", fontWeight: "300" }}>
      Prompt
      <div style={{ display: "flex", alignItems: "center" }}>
        <CancelOutlined style={{color:"red", marginRight: "8px"}} />
        {props.prompt}
      
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
       
        <Done   style={{color:"green", marginRight: "8px"}}/> 
        { props.improvedPrompt}
      </div>
    </Typography>
  )}
</Box>
<Box>
  {props.negativePrompt !== props.improvedNegativePrompt && (
    <Typography gutterBottom align="left" variant="subtitle1" style={{ color: "silver", fontWeight: "300" }}>
      Negative Prompt
      <div style={{ display: "flex", alignItems: "center" }}>
        <CancelOutlined  style={{color:"red", marginRight: "8px"}}/>
        {props.negativePrompt}
     
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
       
        <Done  style={{ color: "green", marginRight: "8px" }}/>
        {props.improvedNegativePrompt}
      </div>
    </Typography>
  )}
</Box>







          </DialogContentText>
        </DialogContent>

        <DialogActions
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
            width: "100%",
            padding: "0px",
          }}
        >
          <Button
            variant="contained"
            style={{
              width: "100%",
              backgroundColor: "#e85275",
              color: "white",
              borderRadius: "5px",
              textTransform: "none",
            }}
            onClick={handleClose}
          >
            Continue
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

export default PromptWarning;
