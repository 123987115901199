import React from "react";
import Meta from "./../components/Meta";
// import GeneratorSection from "./../components/GeneratorSection";
import GallerySection from "../components/GallerySection";
// import { requireAuth } from "./../util/auth";

function GalleryPage(props) {
  return (
    <>
      <Meta
        title="Gallery"
        description="Explore a wide range of uncensored AI-generated art in our gallery."
        // You can add more meta properties here as needed
        meta={{
          robots: "noindex",
        }}
      />
      <GallerySection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Gallery"
        subtitle="Explore others."
      />
    </>
  );
}

export default GalleryPage;
