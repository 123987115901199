import React from "react";
import Meta from "./../components/Meta";
import PricingSection from "./../components/PricingSection";

function PricingPage(props) {

  return (
    <>
     <Meta
        title="Pricing"
        description="Go to Pricing Section and choose the best plan according to your need."
        // You can add more meta properties here as needed
      />
      <PricingSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Pricing"
        subtitle="Affordable Plans for Every Desire
        Unlock the full potential of NSFW Art Generator with our flexible pricing plans. Whether you're an occasional user or a frequent artist, we've got you covered."
      />
    </>
  );
}

export default PricingPage;
