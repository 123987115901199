import React from "react";
import Meta from "./../components/Meta";
import GeneratorSection from "./../components/GeneratorSection";
// import GallerySection from "../components/GallerySection";
// import { requireAuth } from "./../util/auth";

function GeneratorPage(props) {
  document.title = "Create - NSFWartgenerator";

  return (
    <>
      <Meta
        title="Create"
        description="Create NSFW AI Images in High Quality with just a prompt. No Limits."
      />
      <GeneratorSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Craft Your Fantasy"
        subtitle="Dive into the world of AI-driven artistry. With just a few clicks, watch your deepest desires come to life in vivid detail."
        buttonAction="Generate"
        buttonColor="primary"
      />
    </>
  );
}

export default GeneratorPage;
