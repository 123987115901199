import { Box, Typography,List,ListItem} from "@material-ui/core";
import React from "react";

function LegalTerms(props) {
    document.title = "Terms of Service - NSFWartgenerator";

  return (
    <Box my={2}sx={{mt:"2px"}}>
       
    <Box
      sx={{
        width: 'inherit',
        display: 'flex',
        px:"2px",
        mb:"1.6rem",
        
        backgroundColor:"rgba(0, 0, 0, 0.1)",
        flexDirection: 'column',
      }}
    >
      <Typography
        variant="h4"gutterBottom
        sx={{
          color: 'white',
          fontWeight: 'bold',
        }}
      >
        Terms of service
      </Typography>
      <Typography
gutterBottom
        variant="body2"
        sx={{
          color: 'grey.500',
          fontWeight: 'medium',
          textAlign: 'right', // Align text to the right

          
        }}
      >
        Last updated on: 13/10/2023
      </Typography>

      <Box my={2} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography
        gutterBottom

          variant="h5" 
          sx={{
            color: 'white',
            fontWeight: 'medium',
          }}
        >
          Description of Service
        </Typography>
        <Typography
        
          variant="body1"
          sx={{
            color: 'white',
          }}
        >
          Our platform is an AI Image Generation Platform that empowers users to create AI-generated content, specifically images. We provide users with the ability to generate a wide range of images using artificial intelligence technology. It is important to note that the content generated on our platform can be of an explicit or sexual nature, as determined by the users themselves. Our platform offers access to computing capabilities, without directly providing nor producing any content.
        </Typography>
      </Box>

      <Box my={2}sx={{ marginBottom: '1.2rem', display: 'flex', flexDirection: 'column' }}>
        <Typography
          variant="h5" gutterBottom

          sx={{
            color: 'white',
            fontWeight: 'medium',
            marginBottom: '0.6rem',
          }}
        >
          1. Eligibility
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: 'white',
          }}
        >
          You must be at least 18 years old or the age of majority in your jurisdiction to use the Platform. By accessing and using the Platform, you represent and warrant that you meet these age requirements.
        </Typography>
      </Box>

      <Box my={2}sx={{ marginBottom: '1.2rem', display: 'flex', flexDirection: 'column' }}>
        <Typography
          variant="h5" gutterBottom

          sx={{
            color: 'white',
            fontWeight: 'medium',
            marginBottom: '0.6rem',
          }}
        >
          2. Prohibited Content
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: 'white',
          }}
        >
          Users are strictly prohibited from generating or attempting to generate AI-generated images that display any form of rape, murder, non-consensual deepfakes (images portraying real people), minors in any circumstances, violence, severe beatings, watersports, bestiality, prostitution, pedopornography, or related content. Any violation of this provision may result in the immediate termination of your account, content removal, and may also be subject to legal consequences.
        </Typography>
      </Box>

      <Box my={2}sx={{ marginBottom: '1.2rem', display: 'flex', flexDirection: 'column' }}>
        <Typography
          variant="h5" gutterBottom
          sx={{
            color: 'white',
            fontWeight: 'medium',
            marginBottom: '0.6rem',
          }}
        >
          3. Account Creation
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: 'white',
          }}
        >
          Users can join the Platform by signing up through their Google, Twitter, Reddit, or Discord accounts using our convenient one-click signup process. By signing up, you authorize us to access and use your provided information, such as your email (if available) and username, for the purpose of account creation and future access to the Platform.
        </Typography>
      </Box>

      <Box my={2}
      sx={{
        marginBottom: '1.2rem',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        variant="h5" gutterBottom
        sx={{
          color: 'white',
          fontWeight: 'medium',
          marginBottom: '0.6rem',
        }}
      >
        4. Limitation of Liability
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: 'white',
        }}
      >
        To the maximum extent permitted by law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenue, whether incurred directly or indirectly, arising from your use of the Platform.
      </Typography>
    </Box>

    <Box my={2}
      sx={{
        marginBottom: '1.2rem',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        variant="h5" gutterBottom
        sx={{
          color: 'white',
          fontWeight: 'medium',
          marginBottom: '0.6rem',
        }}
      >
        5. Content
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: 'white',
        }}
      >
        Kindly be advised that the quality of the content generated is predominantly contingent upon the inputs provided by the user (prompt), and as such, we cannot offer refunds for credits that have been utilized. Furthermore, it is important to note that we do not possess complete control over the AI output, as it is solely generated by artificial intelligence technology.
      </Typography>
    </Box>

    <Box my={2}
      sx={{
        marginBottom: '1.2rem',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        variant="h5" gutterBottom
        sx={{
          color: 'white',
          fontWeight: 'medium',
          marginBottom: '0.6rem',
        }}
      >
        6. Cancellation policy
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: 'white',
        }}
      >
        You have the freedom to cancel your subscription anytime. Simply reach out to us at support@nsfwartgenerator.ai
      </Typography>
    </Box>

    <Box my={2}
      sx={{
        marginBottom: '1.2rem',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        variant="h5" gutterBottom
        sx={{
          color: 'white',
          fontWeight: 'medium',
          marginBottom: '0.6rem',
        }}
      >
        7. Affiliates
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: 'white',
        }}
      >
        Affiliates are provided with a compelling opportunity to earn a substantial 20% commission on revenue generated from user purchases within our platform. This commission is calculated based on the total revenue received, after deducting payment gateway fees. 
      </Typography>
    </Box>

    <Box my={2}
      sx={{
        marginBottom: '1.2rem',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        variant="h5" gutterBottom
        sx={{
          color: 'white',
          fontWeight: 'medium',
          marginBottom: '0.6rem',
        }}
      >
        8. Images uploads
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: 'white',
        }}
      >
        Our platform strictly prohibits the uploading of images. Users are not permitted to upload any images under any circumstances. We maintain a zero-tolerance policy for image uploads to ensure compliance with all legal requirements and the protection of intellectual property rights.
      </Typography>
    </Box>
    <Box my={2}sx={{ marginBottom: '1.2rem', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h5" gutterBottom sx={{ color: 'white', }}>
        9. Refund policy and liability
      </Typography>
      <List>
      <Typography variant="body1" sx={{ color: 'white', marginBottom: 3 }}>
      1. Non-Liability for Delays or Performance Issues

        </Typography>
        <ListItem sx={{ color: 'white', fontWeight: 'medium', marginBottom: 3 }}>
          We strive to provide a seamless and efficient AI image generation experience. However, due to various factors such as server load, maintenance, or technical issues, the performance of the image generation process may occasionally be delayed or slowed down. You acknowledge and agree that we shall not be liable for any delays or reduced performance during the image generation process.

        </ListItem>
        
        <Typography variant="body1" sx={{ color: 'white', marginBottom: 3 }}>          2. Non-Liability for Damage in Circumstances Beyond Our Control

        </Typography>
        <ListItem sx={{ color: 'white', fontWeight: 'medium', marginBottom: 3 }}>
          We take all reasonable precautions to maintain the security and integrity of our systems. However, in the event of any damage or loss occurring as a result of circumstances beyond our control, including but not limited to cyber-attacks, force majeure events, or any other unforeseen incidents, we shall not be held liable for such damages or losses.

        </ListItem>

        <Typography variant="body1" sx={{ color: 'white', marginBottom: 3 }}>
        3. No Refunds for Slow Image Generation

        </Typography>
        
        <ListItem sx={{ color: 'white', fontWeight: 'medium', marginBottom: 3 }}>
          The image generation process's speed and priority depend on the user's plan, and it is subject to the queue system. We cannot guarantee the specific time it takes to generate images for individual users based on their chosen plan. Therefore, we do not offer refunds if the image generation is slower than expected.

        </ListItem>

        <Typography variant="body1" sx={{ color: 'white', marginBottom: 3 }}>
        4. No Refunds for Unmet Expectations

        </Typography>
        
        <ListItem sx={{ color: 'white', fontWeight: 'medium', marginBottom: 3 }}>
          The images generated by the App are fully AI-generated and rely on the data provided and the AI model's capabilities. While we aim to provide satisfactory results, we cannot guarantee that the images will meet all user expectations or preferences. As such, we do not offer refunds based on the images not turning out as the user expects or wishes.

        </ListItem>
 <Typography variant="body1" sx={{ color: 'white', marginBottom: 3 }}>
        5. Refund Eligibility

        </Typography>
        <ListItem sx={{ color: 'white', fontWeight: 'medium', }}>
         Refunds will only be considered under the following circumstances:

        </ListItem>
       
        <List>
          <ListItem sx={{ color: 'white', fontWeight: 'medium',}}>
            a. Applicable statutory consumer protection laws mandate a refund.
          </ListItem>
          <ListItem sx={{ color: 'white', fontWeight: 'medium',}}>
            b. We fail to provide the service entirely, and the user is unable to use the service for its intended purpose.
          </ListItem>
          <ListItem sx={{ color: 'white', fontWeight: 'medium', }}>
            c. Double billing or overcharging due to a technical error on our part.
          </ListItem>
          <ListItem sx={{ color: 'white', fontWeight: 'medium',  }}>
            d. The user has not consumed any credits provided to him and has not seen more than 30 generations in the discovery section.
          </ListItem>
        </List>
      </List>
    </Box>

    <Box sx={{ marginBottom: '1.2rem', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h5" gutterBottom sx={{ color: 'white', fontWeight: 'medium', marginBottom: '0.6rem' }}>
        10. Restrictions
      </Typography>
      <Typography variant="body1" sx={{ color: 'white', marginBottom: 3, fontWeight: 'medium' }}>
        In connection with the utilization of our Services, you are required to refrain from performing the following actions with regard to any material (including photographs, text, videos, etc.):
      </Typography>
      <List>
        <ListItem sx={{ color: 'white', fontWeight: 'medium', marginBottom: 3 }}>
          a. Uploading, disseminating, or otherwise transmitting content that is in violation of the law, poses harm, damages reputation, is defamatory, exhibits abusive behavior, is racially or ethnically offensive, involves harassment, communicates threats, intrudes upon personal privacy or the right to public image, disseminates false or misleading information, ignites controversy, or otherwise raises objections.
        </ListItem>
        <ListItem sx={{ color: 'white', marginBottom: 3 }}>
          b. Engaging in activities that would incite, promote, or provide guidance for criminal offenses, infringe upon the rights of any party, result in legal accountability, or breach local, regional, national, or international legal statutes.
        </ListItem>
        <ListItem sx={{ color: 'white', fontWeight: 'medium', marginBottom: 3 }}>
          c. Displaying private or personal information pertaining to a third party without acquiring proper authorization from said third party.
        </ListItem>
        <ListItem sx={{ color: 'white', marginBottom: 3 }}>
          d. Crafting content that could potentially exploit or cause harm to minors, either by exposing them to inappropriate subject matter, seeking personally identifiable data, or adopting any other approach.
        </ListItem>
        <ListItem sx={{ color: 'white', marginBottom: 3 }}>
          e. Sharing information or content for which you lack the lawful right.
        </ListItem>
        <ListItem sx={{ color: 'white', marginBottom: 3 }}>
          f. Generating content that, at our exclusive judgment, could be deemed objectionable, or that imposes limitations or barriers upon any individual's use or enjoyment of our Services, potentially exposing us or others to various forms of harm or legal responsibility.
        </ListItem>
      </List>
    </Box>


      <Box my={2} sx={{  display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h5" gutterBottom sx={{ color: 'white',  }}>11. Compliance policy</Typography>
        <Typography variant="body1" sx={{ color: 'white', marginBottom: 3 }}>Users can report any potentially problematic content they come across on our platform. We provide a convenient reporting mechanism to ensure easy reporting of such content.</Typography>
        <Typography variant="body1" sx={{ color: 'white', marginBottom: 3 }}>Review and Resolution: Once a reported complaint is received, our dedicated team will promptly review it within seven business days. We take every reported complaint seriously and strive to resolve them in a fair and timely manner. Our investigations into reported complaints may result in the deletion of content that violates our terms of service. We take appropriate actions to address such violations, including termination of user accounts for repeated generation of illegal content or bypassing our filters.</Typography>
        <List>
          <ListItem sx={{ color: 'white', fontWeight: 'medium' }}><h4>Appeal Process:</h4></ListItem>
          <ListItem sx={{ color: 'white', marginBottom: 3 }}>
<Box><Typography>            <strong>1. Appeal Submission: </strong> To initiate an appeal, you must submit via email a written appeal to support@nsfwartgenerator.ai explaining the reasons why you believe the decision made on your reported complaint was incorrect or unjustified. This appeal should clearly state your case and provide any supporting evidence or relevant information.
</Typography></Box>          </ListItem>
          <ListItem sx={{ color: 'white', marginBottom: 3 }}>
<Box><Typography>            <strong>2. Appeal Review:</strong> Once we receive your appeal, our dedicated team will promptly review it within 7 business days. We will conduct a thorough reevaluation of the reported content and the initial decision made. Our goal is to ensure that all appeals are given fair consideration.
</Typography></Box>          </ListItem>

          <ListItem sx={{ color: 'white', marginBottom: 3 }}>
          <Box>
<Typography>          <strong>3. Appeal Outcome:</strong> Following the review of your appeal, we will communicate the outcome to you within 7 business days. There are three possible outcomes:
</Typography>            
            <List>
              <ListItem sx={{ color: 'white', marginBottom: 3 }}>
<Typography>                <strong>a. Upheld:</strong> If we determine that the initial decision made on your reported content was accurate and in compliance with our terms of service, the decision will be upheld, and the content will remain deleted or action taken against your account will be maintained.
</Typography>
              </ListItem>
              <ListItem sx={{ color: 'white', marginBottom: 3 }}>
<Typography>                <strong>b. Overturned:</strong> If we find that the initial decision was incorrect or unjustified, we will overturn the decision. The reported content will be restored if it was deleted, and any action taken against your account will be reversed.
</Typography>              </ListItem>
              <ListItem sx={{ color: 'white', marginBottom: 3 }}>
<Typography>                <strong>c. Partial Overturn:</strong> In some cases, we may determine that the initial decision was partially incorrect or unjustified. In such situations, we will take appropriate measures to rectify the situation, which may include restoring some or all of the reported content or modifying the action taken against your account.
</Typography>
              </ListItem>
            </List>
          </Box>
          </ListItem>

          <ListItem sx={{ color: 'white', marginBottom: 3 }}>
<Box><Typography>            <strong>4. Final Decision:</strong> The decision made during the appeal process will be considered final. We strive to provide fair and accurate outcomes, but it's important to note that our decisions are based on our interpretation of our terms of service and platform guidelines.
</Typography></Box>          </ListItem>
        </List>
      </Box>

      <Box my={2}sx={{ marginBottom: '1.2rem', display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h5" gutterBottom sx={{ color: 'white', fontWeight: 'medium', fontSize: '2xl', marginBottom: '0.6rem' }}>12. Refills</Typography>
        <Typography variant="body1" sx={{ color: 'white', marginBottom: 3 }}>
          You can request a limited number of credit refills for generations depicting characters with noticeable anatomical issues, like multiple or missing limbs. To be eligible for this, you should not have chosen more than one extension for that specific generation. Please note that this does not apply to issues with hands, feet, fingers, or toes.
        </Typography>
      </Box>

      <Box my={2}sx={{ marginBottom: '1.2rem', display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h5" gutterBottom sx={{ color: 'white', fontWeight: 'medium', fontSize: '2xl', marginBottom: '0.6rem' }}>13. Termination of access</Typography>
        <Typography variant="body1" sx={{ color: 'white', marginBottom: 3 }}>We reserve the right to suspend or terminate your access to the Feature, the application, or any other services provided by us, if we determine, in our sole discretion, that you have violated these Terms of Service.</Typography>
      </Box>

    </Box>

    </Box>
  );
}

export default LegalTerms;
