import React from 'react'
import ChatPageUI from '../components/Chat.js/Chat'

const ChatPage = () => {
  return (
    <div>
      <ChatPageUI />
    </div>
  )
}

export default ChatPage
