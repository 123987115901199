import React, { useState, useEffect } from "react";
import { Container, Grid, Card, Tabs, Tab, Box } from "@material-ui/core";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import { usePagedItemsByOwner, usePagedItemsLiked, useLikeItem, likeItem, usePagedItems, updateUser } from "./../util/db";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link } from "../util/router";
import Gallery from "./Gallery";
import { useAuth } from "./../util/auth";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Switch from '@material-ui/core/Switch';
import LockIcon from '@material-ui/icons/Lock';
import UnlockIcon from '@material-ui/icons/LockOpen';
import DeleteItemForm from "./DeleteItemForm";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 320,
  },

  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(0.5),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: 'primary',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: 'primary',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
    color: 'white'
  },
  track: {
    borderRadius: 26 / 2,
    border: `0px solid ${theme.palette.grey[100]}`,
    backgroundColor: theme.palette.grey[800],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}));

const allyProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function GallerySection(props) {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(0);
  const [nsfwMode, setNsfwMode] = useState(false);
  const auth = useAuth();
  const uid = auth.user ? auth.user.uid : undefined;

  useEffect(() => {
    if (auth.user && auth.user.nsfw){
      setNsfwMode(auth.user.nsfw)
    }
  }, [auth])

  const handleNsfwToggle = () => {
    setNsfwMode(prevState => {
      if (auth.user){
        updateUser(auth.user.uid, {nsfw: !prevState})
      }
      return !prevState
    })
  }


  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };


  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={1}
          textAlign="center"
        />
        {auth.user && auth.user.admin &&
          <DeleteItemForm/>
        }
        <Grid container justifyContent="space-between">
          
          <Tabs value={currentTab} onChange={handleChange} indicatorColor="primary">
            <Tab label="All" {...allyProps(0)} />
            <Tab label="Liked Images" {...allyProps(1)} />
            <Tab label="My Images" {...allyProps(2)} />
            
          </Tabs>
          <Box sx={{paddingRight: 60, paddingTop: 10}}>
            <FormControlLabel
                control={
                  <Switch
                    onChange={handleNsfwToggle}
                    checked={nsfwMode}
                    name="isPrivate"
                    classes={{
                      root: classes.root,
                      switchBase: classes.switchBase,
                      thumb: classes.thumb,
                      track: classes.track,
                      checked: classes.checked,
                    }}
                    edge={"start"}
                    color="primary"/>
                }
                label={
                <Box style={{display: 'flex', flexDirection: 'row'}}>
                  <LockIcon style={{paddingBottom: 4}}></LockIcon>
                  <Typography>NSFW</Typography>
                </Box>
                }
                labelPlacement="start"
              />
          </Box>
        </Grid>
        
        <TabPanel value={currentTab} index={0}>
          <Gallery
            nsfwMode={nsfwMode}
            {...usePagedItems(auth?.user?.planIsActive) }
          />
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
        <Gallery
            nsfwMode={nsfwMode}
            {...usePagedItemsLiked(auth?.user?.likes)}
          />
        </TabPanel>
        <TabPanel value={currentTab} index={2}>
        <Gallery
            nsfwMode={nsfwMode}
            {...usePagedItemsByOwner(uid)}
          />
        </TabPanel>
        
      </Container>
    </Section>
  );
}

export default GallerySection;
